
<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import {
  required,email,maxLength,minLength 
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Corporate Users",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
   Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Add Corporate Users",
      items: [
        {
          text: "Corporate Users",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],


      imgPreUrl:process.env.VUE_APP_FILE_URL,
      status :'',
      submitted: false,
      showDismissibleAlert :false,
      loading1: true,
      loading2: false,
      excelUpload:false,
      dob:"",
      empEmailID:"",
      empName:"",
      empMobileNO:"",
      employeeID:"",
      loading: false,
      file: '',
      departmentList:[],
      department:"",
      branches:[],
      branchID:"",


    };
  },

    validations: {
   
      empName: {
        required,
      },
      empEmailID: {
        required,
        email,
      },
      empMobileNO:{
        required,
        maxLength: maxLength(10),
        minLength: minLength(10),
      }
    
  

  },
  methods:{
    getCorporateBranchByCorpID(){
      this.axios.post(this.$loggedRole+"/getCorporateBranchByCorpID", {corporateID:this.$storageData.profile.corporateID}
    )
    .then((response) => {
    this.branches = response.data.data;
    this.branchID = this.branches[0];
    sessionStorage.setItem('corpBranchID', this.branchID.corpBranchID);
  
      
    });
},
    onchangeBranch(){
       // this.corpBranchID = this.branchID.corpBranchID;
        sessionStorage.setItem('corpBranchID', this.branchID.corpBranchID);
       
    },
    submitCorporateUsers(){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
          let formData = new FormData();  
       
            formData.append('empEmailID', this.empEmailID);
            formData.append('empMobileNO', this.empMobileNO);
            formData.append('employeeID',this.employeeID);
            formData.append('empName', this.empName);
            formData.append('dob', this.dob);
            formData.append('departmentID',JSON.stringify(this.department.departmentID));
            formData.append('corpBranchID',sessionStorage.getItem('corpBranchID'));
            formData.append('createdBy', this.$storageData.profile.pid);
            formData.append('loginTypeID',this.$storageData.login_type);
         
      this.axios.post(this.$loggedRole+"/addCorporateUsers",  formData)
      .then((result)=>{
        this.status = result.data.data;
              this.showDismissibleAlert = true;
              this.loading = false;
//console.log(result);                                                                                                                                                                                                                                                
              setTimeout(() => {
                  this.$router.push({name: 'usersTable' , params: { id: sessionStorage.getItem('corpBranchID')}}); 
             },2000);

      })
     .catch((error)=> {
       this.loading = false;
                  // console.error(error.response);
                        alert(error.response.data.data);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
                    });
      }  
    },
downloadSample(){
            this.axios.get(this.$loggedRole+'/downloadAddUserExcelSample', {
            responseType: 'blob',
            params: {
              branchid: sessionStorage.getItem('corpBranchID')
            }
           
          }).then((response) => {
            const url = URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              `sampleEmployees.xlsx`
            )
            document.body.appendChild(link)
            link.click()
          })

  },
    submitExcelUsers(){
      
      if (this.file.length < 0) {
        alert("Please Choose file for upload");
        return;
      } else {
          let formData = new FormData();  
         
            formData.append('file', this.file);
            formData.append('corpBranchID',sessionStorage.getItem('corpBranchID'));
            formData.append('createdBy', this.$storageData.profile.pid);
            formData.append('loginTypeID',this.$storageData.login_type);

            this.axios.post(this.$loggedRole+"/importCorporateEmployee",  formData, {
              'Content-Type': 'multipart/form-data'
              })
            .then((result)=>{
              this.status = result.data.data;
                    this.showDismissibleAlert = true;
                    this.loading = false;

                     setTimeout(() => {
                          this.$router.push({name: 'usersTable' , params: { id: sessionStorage.getItem('corpBranchID')}}); 
                    },2000);

            })
          .catch((error)=> {
            this.loading = false;
                              
                              alert(error.response.data.data);
                          });
            } 
    },
    getCorporateList(){
         this.axios.get(this.$loggedRole+"/getCorporateBranchList")
          .then((result)=>{
            this.corporates = result.data.data;
              
        });
    },

    isExcelUpload(){
     
        this.excelUpload = !this.excelUpload;
        if(this.excelUpload){
            this.loading1 = false;
            this.loading2 = true;
        }else{
            this.loading1 = true;
            this.loading2 = false;
        }
       
    },
    handleFileUpload: function(event) {
    
      var input = event.target;
            if (input.files && input.files[0]) {
               this.file = input.files[0];
          }
               
   // console.log(this.file);
  },
   getCorporateDetailsByID(corpBranchID){
            this.axios
        .post(this.$loggedRole+"/getCorporateDetailsByID", {'corpBranchID':corpBranchID}
        )
        .then((response) => {
          //Then injecting the result to datatable parameters.

           this.corpData = response.data.data.corpBranchName;
          
          
        });
    },
    getCorporateDepartmentList(){
      this.axios
        .post(this.$loggedRole+"/getCorporateDepartmentList", {'corpBranchID':sessionStorage.getItem('corpBranchID')}
        )
        .then((response) => {
          //Then injecting the result to datatable parameters.

           this.departmentList = response.data.data;
          
          
        });
    }
  

  },
     mounted(){
        if(this.$route.params.id){
           sessionStorage.setItem('corpBranchID',this.$route.params.id);
        }
        if(this.$storageData.profile.corpBranchID>0){
            sessionStorage.setItem('corpBranchID', this.$storageData.profile.corpBranchID);
        }
        if(this.$storageData.profile.empTypeID==5 && this.$storageData.login_type==3){  // corp brand login
          this.getCorporateBranchByCorpID();
        }
        this.getCorporateDepartmentList();
      
     

  },

  middleware: "authentication",
};
</script>

<template>
<Layout>
    <PageHeader :title="title" :items="items" />
      <div class="col-md-3" style="position: absolute;right: 37%;top: 84px;" 
         v-if="this.$storageData.profile.empTypeID==5">

                     <label>Select Corporate Branch* </label>
                        <multiselect v-model="branchID" :options="branches" track-by="corpBranchID" label="corpBranchName"
                          @input="onchangeBranch();" :show-labels="false"></multiselect>
      </div>
     <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
       User Registered Successfully!
    </b-alert>
  </div>
     
     <div class="col-lg-12" style="margin-top:30px;">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                            <h6  style="margin-bottom: 23px;" class="col-md-2">Excel Upload Users:</h6>
                            
                             <div
                              class="form-check form-switch form-switch-md col-md-3"
                              dir="ltr"
                            >
                             <input
                                type="checkbox"
                                class="form-check-input"
                                id="customSwitchsizemd" v-model="this.excelUpload"
                                @change="isExcelUpload()"
                              />
                             
                              </div>

                    </div>
                    <label> Select Excel: </label>
                    <div class="">
                                Choose File
                                <input type="file" :disabled="loading1" class="" @change="handleFileUpload"  >
                    </div>
                    <!-- <p> <a :href="imgPreUrl+'sampleImportCorporateEmp.xlsx'" download>Click here</a>
                     to download sample excel for import Employee.)</p> -->
                    
                    <p> <a href='#' @click="downloadSample();">Click here</a>
                     to download sample excel for adding Employees.)</p>

              
                    <div class =" row" style="float:right;">
                          <b-spinner v-if="loading"
                          class="m-2 col-3"
                          variant="primary"
                          role="status"
                        ></b-spinner>
                    <button type="button" @click="submitExcelUsers();" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading1">Submit</button>
                  </div>

                </div>
               
            </div>
        </div>
        <h6 style="text-align: center;">OR</h6>
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h6 style="margin-bottom: 23px;">Manually Add Users:</h6>
                    
                    <!-- <label class="form-label" for="formrow-endTime-input">Select Corporate Branch </label> -->
                       <!-- <multiselect v-model="posts.corpBranchID" :options="corporates" track-by="corpBranchID" label="corpBranchName"
                      ></multiselect> -->

                    <div class="row">
                    <div class="mb-3 col-md-6">
                        <label class="form-label" for="formrow-branchManager-input">Employee Name *</label>
                        <input type="text" class="form-control" id="formrow-branchManager-input" :disabled="loading2" v-model="empName"
                        :class="{
                        'is-invalid': submitted && $v.empName.$error,
                      }"
                        >
                         <div
                          v-if="submitted && $v.empName.$error"
                          class="invalid-feedback"
                         >
                          <span v-if="!$v.empName.required"
                            >This value is required.</span
                          >
                        </div>
                       
                  </div>
                <div class="mb-3 col-md-6">
                                <label class="form-label" for="formrow-emailID-input">Employee Email ID *</label>
                                <input type="text" class="form-control" id="formrow-emailID-input" :disabled="loading2" v-model="empEmailID"
                                  :class="{
                                    'is-invalid': submitted && $v.empEmailID.$error,
                                  }"
                                > 
                                <div
                                v-if="submitted && $v.empEmailID.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.empEmailID.required"
                                  >This value is required.</span
                                >
                              </div> 
                </div>
                   
              </div>
                <div class="row">
                    <div class="mb-3 col-md-6">
                        <label class="form-label" for="formrow-mobileNO-input">Employee Mobile No* </label>
                        <input type="text" class="form-control" id="formrow-mobileNO-input" :disabled="loading2" v-model="empMobileNO"
                           :class="{
                                    'is-invalid': submitted && $v.empMobileNO.$error,
                                  }"
                        >
                         <div
                                v-if="submitted && $v.empMobileNO.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.empMobileNO.required"
                                  >This value is required.</span
                                >
                              </div> 
                       
                       
                  </div>
                   <div class="col-md-6">
                            <label class="form-label" for="formrow-department-input">Department</label>
                              <multiselect v-model="department"  :options="departmentList" label="departmentName" 
                              track-by="departmentID">
                      
                      </multiselect>
                    </div>
                  
                </div>
                 <div class="row">
                        <div class="mb-3 col-md-6">
                              <label class="form-label" for="formrow-empID-input">EmployeeID </label>
                              <input type="text" class="form-control" id="formrow-empID-input" :disabled="loading2" v-model="employeeID"
                              
                              >
                            
                            
                        </div>
                          <div class="mb-3 col-md-6">
                                      <label class="form-label" for="formrow-DOB-input">Employee DOB </label>
                                      <input type="date" class="form-control" id="formrow-licenseNO-input" :disabled="loading2" v-model="dob">
                                    <!-- <date-picker v-model="dob" :first-day-of-week="1" :disabled="loading2" lang="en"></date-picker> -->
                          </div>
                     </div>
                    <div class =" row" style="float:right;">
                          <b-spinner v-if="loading"
                          class="m-2 col-3"
                          variant="primary"
                          role="status"
                        ></b-spinner>
                    <button type="button" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" @click="submitCorporateUsers();" :disabled="loading2">Submit</button>
                  </div>

                </div>
               
            </div>
        </div>
  

</Layout>
</template>

<style scoped>
.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}
</style>


